import { db, rtdb } from "@/helpers/firebase";
// import "firebase/firestore";
// import localforage from "localforage";

const COLLECTION = "Artwork";
const collectionRef = db.collection(COLLECTION);

class ArtworkDS {
  async read(_id) {
    // const dataPromise = new Promise((resolve, reject) => {
    //   localforage.getItem(_id).then(async (cachedData) => {
    //     const ts = await localforage.getItem(`${_id}TS`) || 0;
    //     // const age = (Math.floor((Date.now()/1000) - ts))/86400; // age in days
    //     const age = (Math.floor((Date.now()/1000) - ts))/60; // age in minutes
    //     if (cachedData && (age < 720 || navigator.Online)) resolve(cachedData);
    //     if (cachedData && (age < 720 || navigator.Online)) resolve(cachedData);
    //     else {
    //       try {
    //         const snapshot = await collectionRef.doc(_id).get();
    //         const data = snapshot.data();
    //         localforage.setItem(_id, data);
    //         localforage.setItem(`${_id}TS`, Math.floor(Date.now()/1000));
    //         resolve(data);
    //       } catch {
    //         reject();
    //       }
    //     }
    //   });
    // });
    // return dataPromise;
    const snapshot = await collectionRef.doc(_id).get();
    const data = snapshot.data();
    return data;
  }

  getCollectorsArtwork(userId) {
    return collectionRef.where(`collectors.${userId}`, ">", 0).get();
  }

  async getPreprodArtworkRT() {
    let  dbArtwork = await rtdb.ref("artwork").child("preprod").get();
  
    const artwork = dbArtwork.val();

    let preprod = []

    for (let artworkId in artwork) {
      const info = artwork[artworkId].info;
      const metrics = artwork[artworkId].metrics;
      const data = {
        _id: artworkId,
        title: info.artwork,
        artist: info.artist,
        currency: metrics.currency,
        currentValue: metrics.currentValue,
        originalValue: metrics.originalValue,
        currentFrameValue: metrics.currentFrameValue,
        originalFrameValue: 1000,
        src: `https://storage.googleapis.com/images.neoone.art/artwork/preprod/${artworkId}/preprod_${artworkId}.jpg?3`,
        flex: 3,
      };
      preprod.push(data);
    }
    return preprod;
  }

  async getPrereleaseArtworkRT(published = true) {
    let  dbArtwork = published === null ? await rtdb.ref("artwork").child("prerelease").get() : await rtdb.ref("artwork").child("prerelease").orderByChild('info/published').equalTo(published).get();
  
    const artwork = dbArtwork.val();

    let preprod = []

    for (let artworkId in artwork) {
      const info = artwork[artworkId].info;
      const metrics = artwork[artworkId].metrics;
      const data = {
        _id: artworkId,
        title: info.artwork,
        artist: info.artist,
        currency: metrics.currency,
        currentValue: metrics.currentValue,
        originalValue: metrics.originalValue,
        currentFrameValue: metrics.currentFrameValue,
        originalFrameValue: 1000,
        clickable: info.clickable,
        src: `https://storage.googleapis.com/images.neoone.art/artwork/${artworkId}/${artworkId}_1.jpg?3`,
        flex: 3,
      };
      preprod.push(data);
    }
    return preprod;
  }

  async getPublishedArtworkRT() {
    let dbArtwork;
    let firstRound = [];
    let featured = [];
    // let cachedArtworkEmpty = { featured, firstRound };
    // if (!navigator.onLine) {
    //   try {
    //     let cachedArtwork = await localforage.getItem("artwork");
    //     // IndexedDB did not find the data, try localStorage
    //     if (!cachedArtwork) {
    //       cachedArtwork = localStorage.getItem("artwork");
    //       if (cachedArtwork === null) return cachedArtworkEmpty;
    //       else return JSON.parse(cachedArtwork);
    //     } else {
    //       return cachedArtwork;
    //     }
    //   } catch (e) {
    //     return cachedArtworkEmpty;
    //   }
    // }
    try {
      dbArtwork = await rtdb.ref("artwork").child("published").get();
    } catch {
      console.log('unable to fetch data');
      // try {
      //   let cachedArtwork = await localforage.getItem("artwork");
      //   // IndexedDB did not find the data, try localStorage
      //   if (!cachedArtwork) return cachedArtworkEmpty;
      //   else return cachedArtwork;
      // } catch (e) {
      //   return cachedArtworkEmpty;
      // }
    }

    const artwork = dbArtwork.val();
    delete artwork['tm6Fm3TI0DeLwwC246AO']
    delete artwork['ZEq93Anah09hYV9NWccc']
    for (let artworkId in artwork) {
      const info = artwork[artworkId].info;
      const metrics = artwork[artworkId].metrics;
      const data = {
        _id: artworkId,
        title: info.artwork,
        artist: info.artist,
        stock: info.availableFrames,
        year: info.year,
        currency: metrics.currency,
        currentValue: metrics.currentValue,
        originalValue: metrics.originalValue,
        currentFrameValue: metrics.currentFrameValue,
        originalFrameValue: 1000,
        clickable: true,
        new: info.new,
        src: `https://storage.googleapis.com/images.neoone.art/artwork/${artworkId}/${artworkId}_1.jpg`,
        flex: 3,
      };
      if (info.featured) {
        featured.push(data);
      }
      firstRound.push(data);
    }
    // localforage.setItem("artwork", { featured, firstRound }, function (err) {
    //   // if err is non-null, we got an error
    //   if (err) console.log("failed to save cache ", err);
    // });

    return { featured, firstRound };
  }

  where(filters) {
    let query = collectionRef;
    for (let f in filters) {
      const filter = filters[f];
      query = query.where(filter.field, filter.operator, filter.condition);
    }
    return query;
  }

  async getPublishedArtwork() {
    const filters = [{ field: "published", operator: "==", condition: true }];
    let dbArtwork = await this.where(filters).get();
    let firstRound = [];
    let featured = [];
    dbArtwork.forEach((doc) => {
      const info = doc.data();

      const data = {
        _id: doc.id,
        title: info.title,
        artist: info.artist,
        circa: info.circa,
        year: info.year,
        currency: info.currency,
        currentValue: info.currentValue,
        originalValue: info.originalValue,
        currentFrameValue: info.currentFrameValue,
        originalFrameValue: 1000,
        new: info.new,
        src: `https://storage.googleapis.com/images.neoone.art/artwork/${doc.id}/${doc.id}_1.jpg?1`,
        flex: 3,
      };
      if (info.featured) {
        featured.push(data);
      }
      firstRound.push(data);
    });

    return { featured, firstRound };
  }

  getArtworkMetrics(id) {
    return rtdb
      .ref("artwork")
      .child("published")
      .child(id)
      .child("metrics")
      .get();
  }
  getArtworkInfo(id) {
    return rtdb.ref("artwork").child("published").child(id).child("info").get();
  }

  getArtworkTotalFrames(workID) {
    return rtdb
      .ref("/artwork/")
      .child("published")
      .child(workID)
      .child("metrics/totalFrames")
      .get();
  }

  refArtworkMetrics(id, system="published") {
    return rtdb.ref(`artwork/${system}/${id}/metrics`);
  }
}

export default new ArtworkDS();
