<template>
  <div>
    
    <Collection
      v-if="featured.length > 0"
      :cards="featured"
      title="Featured"
      :cardsPerRow="3"
    />
    <Collection
      :cards="firstRound"
      :title="$t('firstRound')"
      :cardsPerRow="3"
    />
    <Collection
      v-if="resell.length > 0"
      :cards="resell"
      title="Resell"
      :cardsPerRow="3"
    />
    <Collection
      v-if="preprod.length > 0"
      :cards="preprod"
      :title="$t('nextRelease')"
      :cardsPerRow="3"
    />
    
  </div>
</template>

<script>
//      pathForCards='none'
import Collection from "@/components/Templates/Collection";

import ArtworkDS from "@/services/ArtworkDS";

export default {
  name: "ArtWorks",
  components: {
    Collection,
  },
  data: () => ({
    firstRound: [],
    resell: [],
    featured: [],
    preprod: [],
  }),
  beforeMount() {
    ArtworkDS.getPublishedArtworkRT().then((results) => {
      this.featured = results.featured;
      this.firstRound = results.firstRound;
      console.log(this.firstRound)
    });
    ArtworkDS.getPrereleaseArtworkRT().then((preprod) => {
      preprod.forEach((e) => {
        this.preprod.push(e)
      })
    });
    
    
    
  },
};
</script>
