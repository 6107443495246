<template>
  <div class="sold-out" :class="{ 'sold-out--corner': corner }">
    <div>Sold Out</div>
  </div>
</template>

<script>
export default {
  name: 'SoldOut',
  props: {
    corner: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.sold-out {
  width: 100px;
  height: 40px;
  color: var(--color-white);
  font-weight: 600;
  text-align: center;
  background-color: red;
  display: flex;
  border-radius: 2px;
  box-shadow: var(--shadow1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-5deg);
  &--corner {
    top: 5px;
    left: auto;
    right: 5px;
    transform: rotate(-5deg);
  }

  > * {
    margin: auto;
    letter-spacing: -1px;
    text-transform: uppercase;
  }
}
</style>
